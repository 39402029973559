.spinner {
    display: inline-block;
    height: 28px;
    width: 28px;
    animation: rotate 0.8s infinite linear;
    border: 5px solid #ff4056;
    border-right-color: transparent;
    border-radius: 50%;
}

.spinner.bright {
    border-color: #fff;
    border-right-color: transparent;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
