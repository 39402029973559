.sidebar {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 48px;
    width: 400px;
    background: #f2f3f7 url(../assets/register_bg.png) center top no-repeat;
    overflow: hidden;
    min-width: 400px;
}

.sidebar::after {
    position: absolute;
    content: '';
    margin-top: 250px;
    top: 0;
    left: 0;
    width: 100%;
    height: 633px;
    background: url(../assets/register_products.png) no-repeat;
    z-index: 0;
}

.inner {
    position: relative;
    font-size: 17px;
    color: #797d94;
    line-height: 1.56;
    padding-top: 50px;
    z-index: 1;
}

.inner h1 {
    margin: 0 0 20px 0;
    color: #323647;
    font-size: 21px;
    font-weight: 400;
}

.inner p {
    margin: 0 0 40px 0;
    font-size: 18px;
    line-height: 1.56;
    color: #797d94;
}

@media screen and (min-height: 1015px) {
    .sidebar::after {
        margin-top: 0;
        top: auto;
        bottom: 0;
    }
}

@media screen and (min-height: 1097px) {
    .sidebar {
        background-position: center bottom;
    }
}

@media screen and (max-width: 991px) {
    .sidebar {
        display: none;
        padding: 0 15px;
        background: transparent;
        width: auto;
        min-width: auto;
    }
}
