.form {
    width: 364px;
    margin: 0 auto;
}

.row {
    width: 100%;
    padding-bottom: 24px;
}

.actions {
    width: 100%;
    margin-top: 32px;
}

@media screen and (max-width: 767px) {
    .form {
        width: 100%;
    }
}