.root {
    margin-bottom: 28px;
}

@media screen and (min-width: 768px) {
    .root {
        width: 48%;
    }
}

@media screen and (min-width: 992px) {
    .root {
        width: 253px;
    }
}
