.cookieModalWrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    display: block;
    width: 100vw;
    height: 100vh;
    padding: 0;
    background-color: rgba(0,0,0,.72);
    border: none;
}

.cookieModal {
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    overflow-x: hidden;
    overflow-y: visible;
    transform: translate(-50%,-50%);
    padding: 40px;
    max-height: calc(100% - 160px);
}

.cookie-modal-wrapper.hidden {
    display: none;
}

.cookieModal h2 {
    font-size: 27px;
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 1.25;
}

.cookieModal p {
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 1.5;
    font-weight: 400;
}

.cookieModal a,
.cookieModalLink {
    text-decoration: underline;
    color: #323647;
    cursor: pointer;
    transition: color 0.3s;
}

.cookieModal a:hover,
.cookieModalLink:hover {
    color: #629e14;
}

.cookieModalActions {
    display: flex;
    justify-content: space-between;
}

.cookieModalConfiguration {
    flex-wrap: wrap;
    padding-top: 40px;
    margin-top: 40px;
    display: flex;
    border-top: 1px solid #dcdee6;
}

.cookieModalConfiguration > div {
    flex-basis: 50%;
}

.cookieModalConfiguration.expanded {
    display: flex;
}

.checkbox {
    flex-basis: 50%;
}

.buttonWrapper {
    text-align: right;
    margin-top: 30px;
}

@media screen and (min-width: 1540px) {
    .cookieModal {
        max-width: 33%;
        width: auto;
    }
}

@media screen and (max-width: 1010px) {
    .cookieModal {
        width: 75%;
    }
}

@media screen and (max-width: 768px) {
    .cookieModal {
        width: 90%;
    }
}

@media screen and (max-width: 562px) {
    .cookieModal {
        padding: 20px 10px;
        margin-top: -40px;
    }

    .cookieModalActions {
        flex-direction: column-reverse;
    }

    .cookieModalActions button:not(:first-of-type) {
        margin-bottom: 10px;
    }

    .cookieModal p {
        font-size: 16px;
    }

    .buttonWrapper {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-height: 450px) {
    .cookieModal {
        max-height: calc(100% - 80px);
    }
}
