.root {
    background: #fff;
}

.icon {
    width: 129px;
    height: 111px;
    display: block;
    margin: 0 auto 20px;
    background: url(./assets/icon.svg) no-repeat;
    background-position: -18px;
    background-size: 163px 163px;
}

.actions {
    margin-top: 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.message {
    text-align: center;
    line-height: 1.5;
}

.actions.spaceAround {
    justify-content: space-around;
}
