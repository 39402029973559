.root ul {
    margin: 0;
    padding: 0 0 32px;
    width: 100%;
    font-size: 0;
}

.root li {
    display: inline-block;
    vertical-align: top;
}
.product {
    text-decoration: none;
    display: block;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: none;
    transition: box-shadow .3s ease-in;
}
.product:hover {
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
}
.product .status {
    text-transform: uppercase;
    border-radius: 16px;
    padding: 5px 16px 7px;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 18px;
}
.product .image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 100%;
    width: 270px;
    height: 270px;
    margin: 12px auto;
    font-size: 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    color: #5f637a;
    font-weight: 500;
    text-align: center;
}

.product .image.imageNotAvailable {
    font-size: 1.2rem;
    text-transform: uppercase;
}

.product .image.imageNotAvailable svg {
    width: 120px;
    height: 120px;
    display: block;
    margin: 0 auto;
}

.product .categories {
    margin: 0;
    display: block;
    font-size: 1.2rem;
    line-height: 18px;
    color: #797d94;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    word-break: break-all;
}
.product h3 {
    display: block;
    display: -webkit-box;
    font-size: 2rem;
    line-height: 24px;
    height: 48px;
    color: #323647;
    margin: 4px 0 24px;
    font-weight: 300;
    -webkit-line-clamp: 2;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.product .content p {
    text-align: left;
    margin: 0;
    font-size: 1.2rem;
    line-height: 14px;
    color: #797d94;
}
.product .content svg {
    display: inline-block;
    vertical-align: middle;
    width: 14px;
    height: 14px;
    margin-right: 5px;
}
.product .content p span {
    vertical-align: middle;
    display: inline-block;
    width: calc(100% - 19px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    word-break: break-all;
}
.product .content p:first-of-type {
    padding: 12px 0 14px;
    border-bottom: 1px solid #dcdee6;
}
.product .content p:last-of-type {
    padding: 19px 0 4px;
}
.product .footer {
    position: relative;
    border-top: 1px solid #dcdee6;
    text-align: center;
}
.product .footer button {
    position: absolute;
    left: 50%;
    top: calc(50% + 16px);
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
}

.loader {
    padding: 20px 0 45px;
    font-size: 1.4rem;
    line-height: 30px;
    font-weight: 500;
    text-align: center;
    color: #ff4056;
    text-transform: uppercase;
}

.loader svg {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    margin-right: 8px;
}

.loader svg path {
    fill: currentColor;
}

@media screen and (min-width: 1200px) {
    .root li {
        width: calc(33.3333% - 22px);
        margin-top: 32px;
    }
    .root li:nth-of-type(3n-1) {
        margin-left: 33px;
        margin-right: 33px;
    }
    .product {
        padding: 32px;
    }
}

@media screen and (max-width: 1199px) {
    .product .footer button {
        top: calc(50% + 8px);
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .root ul {
        padding-top: 16px;
    }
    .root li:nth-of-type(3n-1) {
        margin-left: 15px;
        margin-right: 15px;
    }
    .root li {
        margin-top: 16px;
        width: calc(33.3333% - 10px);
    }
    .product {
        padding: 32px 16px 16px;
    }
}


@media screen and (max-width: 991px) {
    .root ul {
        padding-bottom: 95px;
    }
    .root li {
        margin-top: 15px;
        max-width: 372px;
        width: calc(50% - 8px);
    }
    .product {
        padding: 30px 15px 15px;
    }
}

@media screen and (min-width: 768px) {
    .product:hover .footer button {
        visibility: visible;
        pointer-events: auto;
        opacity: 1;
        display: inline-block;
    }
    .product:hover .content {
        opacity: 0;
    }
}

@media screen and (max-width: 767px) {
    .product .content p:first-of-type span {
        overflow-x: auto;
    }
}

@media screen and (min-width: 360px) and (max-width: 991px) {
    .root li:nth-of-type(odd) {
        margin-right: 8px;
    }
    .root li:nth-of-type(even) {
        margin-left: 8px;
    }
}

@media screen and (max-width: 639px) {
    .product .categories {
        display: none;
    }
    .product img {
        margin: 5px auto;
        width: 210px;
        height: 210px;
    }
    .product .status {
        padding: 3px 5px;
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 499px) {
    .product {
        padding-top: 15px;
    }
    .product h3 {
        font-size: 1.4rem;
        line-height: 16px;
        height: 32px;
        margin: 4px 0 8px;
    }
    .product .content {
        font-size: 1rem;
        line-height: 13px;
    }
    .product .content p:first-of-type {
        padding: 6px 0 7px;
    }
    .product .content p:last-of-type {
        padding: 9px 0 1px;
    }
    .product img {
        width: 160px;
        height: 160px;
    }
    .product .status {
        padding: 3px 5px;
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 419px) {
    .product img {
        width: 130px;
        height: 130px;
    }
    .product .status {
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 359px) {
    .root ul {
        text-align: center;
    }
    .root li {
        text-align: left;
        margin-top: 15px;
        width: 100%;
        max-width: 290px;
    }
    .product {
        padding: 20px;
    }
    .product .image {
        width: 120px;
        height: 120px;
    }
    .product .status {
        font-size: 1.2rem;
        padding: 3px 5px;
    }
}