.root {
    width: 100%;
    height: 81px;
    background-color: #fff;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
}
svg path,
svg circle {
    mask: none;
}
:global(.content) {
    width: 100vw;
    max-width: 100%;
    margin-top: 81px;
}
.burger {
    display: none;
}
.burger:hover {
    cursor: pointer;
}
.logoLink img {
    width: 150px;
    margin-left: 24px;
    margin-top: 5px;
}
.shortcuts {
    display: flex;
    align-items: center;
    height: 81px;
    line-height: 81px;
}
.shortcuts > a {
    position: relative;
    margin: 0 13px;
    font-size: 1.4rem;
    font-weight: 500;
    text-transform: uppercase;
}
.shortcuts > a{
    color: #5f637a;
}
.shortcuts svg {
    float: left;
    margin: 25.5px 8px 25.5px 0;
    width: 30px;
    height: 30px;
}
.shortcuts > a:hover {
    cursor: pointer;
}
.shortcuts > a.active,
.shortcuts > a:hover {
    color: #ff4056;
}
.shortcuts > a.active svg path,
.shortcuts > a:hover svg path {
    fill: #ff4056;
    mask: none;
}
.shortcuts > .active:before,
.shortcuts > a:hover:before {
    position: absolute;
    bottom: 0px;
    left: 0px;
    content: '';
    width: 100%;
    height: 2px;
    border-bottom: 2px solid #ff4056;
}
.user {
    display: flex;
    position: relative;
    align-items: center;
    margin: 0 23px;
}
.bellIcon {
    width: 30px;
    height: 30px;
    margin: 28px;
}
.bellIcon:hover {
    cursor: pointer;
}
.bellIcon.noNewNotifications circle {
    display: none;
}
.profile {
    z-index: 200;
    position: relative;
    display: flex;
    align-items: center;
}
.profile:hover {
    cursor: pointer;
}
.profile .details {
    display: flex;
    align-items: center;
}
.profile .details .photo {
    width: 49px;
    height: 49px;
    border-radius: 50% 50%;
    background-color: #adb2bd;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 500;
    color: #fff;
    line-height: 49px;
}
.profile .details ul {
    list-style: none;
}
.name, .type {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
}
.name {
    font-size: 1.4rem;
    line-height: 1.71;
    font-weight: 500;
    color: #484b61;
}
.type {
    font-size: 1.2rem;
    line-height: 1.5;
    color: #797d94;
}
.profile .arrow {
    width: 20px;
    height: 20px;
    margin-left: 11px;
    stroke: #adb2bd;
}
.root.shown .profile .arrow {
    transform: rotate(180deg);
}
.dropdown {
    display: none;
    position: absolute;
    width: 180px;
    padding: 16px;
    top: 60px;
    right: 0px;
    z-index: 200;
    border-radius: 4px;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1);
    color: #323647;
    background-color: #ffffff;
}
.root.shown .profile .dropdown {
    display: block;
}
.dropdown > .section {
    position: relative;
    padding: 42px 0px 15px;
    border-top: 1px solid #babcc2;
    border-bottom: 1px solid #babcc2;
    margin: 15px 0;
}
.dropdown > .section:before {
    position: absolute;
    content: 'Zarzadzanie';
    text-transform: uppercase;
    font-size: 1.1rem;
    top: 20px;
    line-height: 1;
    letter-spacing: 1.5px;
    color: #797d94;
    margin: 0 0 20px;
}
.dropdown .option {
    color: #797d94;
    line-height: 30px;
    padding: 5px 0px;
    display: block;
}
.dropdown .option:hover {
    color: #ff4056;
    cursor: pointer;
}
.dropdown .option:hover svg path {
    fill: #ff4056;
}
.dropdown .option svg {
    float: left;
    margin-right: 8px;
}
.dropdown .option svg path {
    fill: #bfc2cb;
}
.return {
    display: none;
}

.root.visibleNotifications .notifications {
    display: block;
}

.notifications {
    display: none;
    position: absolute;
    width: 320px;
    top: 75px;
    left: -244px;
    border-radius: 4px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    font-weight: normal;
}

.notifications header {
    height: 54px;
    padding: 16px;
    font-size: 1.8rem;
    border-bottom: solid 1px #dcdee6;
}

.notifications header:before {
    content: '';
    position: absolute;
    top: -8px;
    right: 25px;
    width: 16px;
    height: 16px;
    transform: rotate(-45deg);
    background-color: #ffffff;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
}

.notifications header:after {
    content: '';
    position: absolute;
    top: 0;
    right: 15px;
    width: 37px;
    height: 14px;
    background-color: #ffffff;
}

.notifications .arrowBack {
    display: none;
}

.notifications .list {
    max-height: 387px;
    overflow-y: auto;
}

.notifications .list::-webkit-scrollbar {
    width: 6px;
    margin-left: 5px;
}

.notifications .list::-webkit-scrollbar-track {
    background-color: #e9eaf0; 
    border-radius: 5px;
}
 
.notifications .list::-webkit-scrollbar-thumb {
    background-color: #b0b3bf; 
    border-radius: 5px;
}

.notifications .list::-webkit-scrollbar-thumb:hover {
    background-color: #797d94; 
}

.notification {
    height: 112px;
    font-size: 1.2rem;
    cursor: pointer;
    border-left: solid 4px transparent;
    padding: 16px;
}

.notification:hover {
    border-left-color: #ff4056;
}

.notification.unread {
    background-color: #ffeef1;
}

.notification + .notification {
    border-top: solid 1px #dcdee6;
}

.notification .image {
    float: left;
    width: 48px;
    height: 48px;
    margin: 0 16px 40px 0;
    border-radius: 6px;
    border: solid 2px #dcdee6;
    background-color: #ffffff;
}

.notification .title {
    font-size: 1.4rem;
    color: #484b61;
    line-height: 1.3;
}

.notification .productName {
    font-weight: 600;
    line-height: 1.2;
    margin: 5px 0 3px;
    overflow-wrap: break-word;
    max-height: 2.4em;
    overflow: hidden;
}

.notification .timeInfo {
    color: #b0b3bf
}

.notification .timeInfo svg {
    width: 16px;
    height: 16px;
    fill: #b0b3bf;
    margin: 0 4px -4px 0;
}

.notification.unread .timeInfo {
    color: #ff4056;
}

.notification.unread .timeInfo svg path {
    fill: #ff4056;
}

.noNotifications {
    text-align: center;
    padding: 16px;
    font-size: 1.4rem;
}

.loader {
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.8;
    color: #ff4056;
    text-transform: uppercase;
    margin: 6px 0 9px;
}

.loader svg {
    width: 24px;
    height: 24px;
    margin: 0 8px -8px 0;
    animation: rotate 0.8s infinite linear;
}

.loader svg path {
    fill: currentColor;
}

@media screen and (max-width: 991px) {
    .root {
        height: 54px;
        margin-bottom: 54px;
        border-bottom: 0.5px solid #dcdee6;
    }
    :global(.content) {
        margin-top: 108px;
    }
    .root.shown {
        margin-left: 300px;
    }
    .root.shown + :global(.content) {
        position: absolute;
        left: 300px;
    }
    .root.shown:after, .root.visibleNotifications:after {
        position: fixed;
        content: '';
        top: 0;
        left: 300px;
        bottom: 0;
        right: 0;
        background-color: rgba(28, 30, 37, 0.1);
    }

    .root.visibleNotifications:after {
        left: 0;
        right: 300px;
    }

    .root.visibleNotifications + :global(.content),
    .root.visibleNotifications .burger,
    .root.visibleNotifications .logoLink,
    .root.visibleNotifications .shortcuts {
        transform: translateX(-300px);
    }

    .burger {
        order: 0;
        display: block;
        margin: 12px 18px;
    }
    .logoLink {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 14px;
    }

    .logoLink img {
        order: 1;
        width: 102px;
        margin-top: 0;
        margin-left: 0;
    }
    .shortcuts svg {
        display: none;
    }
    .shortcuts > div {
        margin: 0 20px;
    }
    .user {
        order: 3;
        margin: 0;
    }
    .bellIcon {
        margin: 12px 18px;
    }
    .profile {
        display: none;
        position: fixed;
        flex-wrap: wrap;
        top: 0;
        left: 0;
        bottom: 0;
        width: 300px;
        background-color: #fff;
        padding: 0;
        margin: 0;
    }
    .root.shown .profile .return {
        display: flex;
        flex-basis: 100%;
        width: 300px;
        height: 54px;
        background-color: #f2f3f7;
        font-size: 18px;
        line-height: 54px;
        color: #323647;
    }
    .root.shown .profile {
        display: block;
    }
    .root.shown .profile .details {
        margin: 0 24px;
        padding: 32px 0;
        border-bottom: 1px solid #babcc2;
    }
    .profile .arrow {
        display: none;
        width: 14px;
        height: 8px;
    }
    .profile .arrow path {
        stroke: #797d94;
    }
    .root.shown .profile .dropdown {
        position: relative;
        width: calc(100% - 20px);
        top: 0;
        left: 0;
        margin-left: 10px;
        box-shadow: none;
    }
    .profile .arrowBack {
        margin: 0 12px 0 23px;
    }

    .notifications {
        position: fixed;
        left: auto;
        right: 0;
        top: 0;
        width: 300px;
        height: 100%;
    }

    .notifications header {
        cursor: pointer;
        display: flex;
    }

    .notifications .list {
        max-height: 100%;
        max-height: calc(100% - 54px);
    }

    .arrowBack {
        width: 14px;
    }

    .notifications .arrowBack {
        display: inline-block;
        margin: 0 12px;
    }

    .notification:hover {
        border-left-color: transparent;
    }

    .logo {
        margin-left: 0;
    }
}

@media screen and (max-width: 767px) {
    :global(.pageProduct) .root, :global(.pageCompany) .root {
        display: none;
    }
    :global(.formEdit .content) {
        margin-top: 48px;
    }
}
@media screen and (max-width: 991px) {
    .shortcuts {
        position: absolute;
        display: flex;
        justify-content: center;
        margin: 0 auto 0;
        border-top: none;
        height: 54px;
        top: 54px;
        left: 0;
        right: 0;
        line-height: 54px;
    }
    .shortcuts:before {
        content: '';
        position: absolute;
        height: 54px;
        width: 150%;
        background: #fff;
        border-top: 1px solid #dadde5;
        top: -1px;
        left: -70px;
        z-index: -1;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
