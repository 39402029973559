.root {
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0px;
    padding: 20px;
    box-shadow: 0 -5px 20px 0 rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    height: 90px;
    width: 100%;
    z-index: 200;
}

.root > * {
    margin: 0px 10px;
}

@media screen and (max-width: 551px) {
    .root {
        height: 150px;
    }
    .root > * {
        margin: 0 30px 10px;
    }
}
