.dialog {
    width: 90%;
    height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 1348px;
    max-height: 874px;
    background: #fff;
    margin: 0 auto;
    border-radius: 6px;
    position: absolute;
}

