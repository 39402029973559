.root {
    text-transform: uppercase;
    min-width: auto !important;
}

.grow0 {
    width: calc(50% - 5px);
}

.grow1 {
    width: calc(50% - 5px);
}

@media (max-width: 767px) {
    .root {
        padding-left: 20px !important;
        padding-right: 21px !important;
    }
}
