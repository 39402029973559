.root {
    position: relative;
    top: -16px;
    margin: 0 0 0 44px;
    text-transform: uppercase;
}

@media screen and (max-width: 991px) {
    .root {
        display: initial;
        display: block;
        margin: 16px auto 0;
    }
}

@media screen and (max-width: 767px) {
    .root {
        width: 100%;
        margin: 18px 0 8px;
    }
}