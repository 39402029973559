.goBack {
    display: inline-block;
    width: 100%;
    font-size: 1.8rem;
    line-height: 1.22;
    border-bottom: 2px solid #dcdee6;
    color: #323647;
    padding: 20px 0;
    z-index: 2;
    text-decoration: none;
    transition: opacity 0.3s ease-in;
    will-change: opacity;
}

.goBack:hover {
    opacity: 0.8;
}

.goBack svg {
    width: 18px;
    height: 10px;
    margin-right: 12px;
    fill: #c0c2cc;
}

.goBack span {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.8rem;
    line-height: 22px;
    color: #323647;
}

.root h2 {
    margin: 48px 0;
    font-size: 3.4rem;
}

.root h3 {
    font-size: 2.8rem;
    margin-bottom: 24px;
}

.root h4 {
    font-size: 2.2rem;
    margin: 32px 0 14px;
}

.company {
    border-bottom: 2px solid #dcdee6;
}

.company section {
    float: right;
    width: 75%;
    padding: 36px;
    margin-bottom: 24px;
    background-color: #fff;
    border-radius: 6px;
}

.company section.requests {
    float: left;
    width: 270px;
    padding: 32px 24px;
}

.requests .count {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.2rem;
    font-weight: 500;
    padding-bottom: 32px;
    margin-bottom: 24px;
    border-bottom: solid 1px #dcdee6;
}

.requests .count svg {
    width: 30px;
    height: 30px;
    margin-right: 12px;
}


.requests .type {
    display: flex;
    align-items: center;
    color: #797d94;
}

.requests .type + .type {
    margin-top: 24px;
}

.requests .type.new .circle {
    background-color: #d6f1f4;
    color: #0d7f95;
}

.requests .type.accepted .circle {
    background-color: #d9f5e6;
    color: #18a34e;
}

.requests .type.rejected .circle {
    background-color: #ffd7dc;
    color: #e6374b;
}

.circle {
    display: inline-block;
    width: 45px;
    height: 45px;
    line-height: 45px;
    border-radius: 50%;
    font-weight: 600;
    text-align: center;
    margin-right: 16px;
}

.requests .status {
    display: inline-block;
    max-width: 105px;
    font-size: 1.6rem;
    font-weight: normal;
}


.products li {
    display: inline-block;
    vertical-align: top;
    width: 270px;
    margin-bottom: 30px;
    margin-left: 33px;
}

.products h3 {
    margin: 36px 0;
}

.product {
    color: #797d94;
    padding: 24px;
    text-decoration: none;
    display: block;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: none;
    transition: box-shadow .3s ease-in;
}

.product:hover {
    color: #797d94;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
}

.product .status {
    text-transform: uppercase;
    border-radius: 16px;
    padding: 5px 16px 7px;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 18px;
}

.product .image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 100%;
    width: 175px;
    height: 175px;
    margin: 12px auto;
    font-size: 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    color: #5f637a;
    font-weight: 500;
    text-align: center;
}

.product .image.imageNotAvailable {
    font-size: 1.2rem;
    text-transform: uppercase;
}

.product .image.imageNotAvailable svg {
    width: 120px;
    height: 120px;
    display: block;
    margin: 0 auto;
}

.product h4.productName {
    color: #323647;
    font-size: 1.8rem;
    margin: 8px 0 16px;
    height: 2em;
    overflow: hidden;
}

.product .categoryName {
    font-size: 1.2rem;
    height: 2.2em;
    overflow: hidden;
}

.product .footer {
    border-top: 1px solid #dcdee6;
    padding-top: 18px;
}

.product .footer span {
    font-size: 1.2rem;
}

.product .footer svg {
    width: 14px;
    height: 14px;
    margin: 0 4px -3px 0;
}

.loader {
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.8;
    color: #ff4056;
    text-transform: uppercase;
    margin: 6px 0 9px;
}

.loader svg {
    width: 24px;
    height: 24px;
    margin: 0 8px -8px 0;
    animation: rotate 0.8s infinite linear;
}

.loader svg path {
    fill: currentColor;
}

@media screen and (min-width: 1200px) {
    .products li:nth-child(4n + 1) {
        margin-left: 0;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1220px) {
    .products li {
        margin-left: 24px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .company section {
        width: 68%;
    }
    .products li {
        margin-left: 60px;
    }
    .products li:nth-child(3n + 1) {
        margin-left: 0;
    }
}

@media screen and (max-width: 991px) {
    .root {
        position: absolute;
        z-index: 1000;
        top: 0;
        left: 0;
        right: 0;
        background-color: #f2f3f7;
        padding: 0 16px;
    }
    
    .root .goBack {
        position: fixed;
        left: 0;
        display: flex;
        align-items: center;
        height: 54px;
        padding: 0 16px;
        background-color: #fff; 
        border: none;
    }

    .root h2 {
        margin: 78px 0 24px 8px;
        font-size: 2.2rem;
    }
    
    .root h3 {
        font-size: 2rem;
    }
    
    .root h4 {
        font-size: 1.8rem;
    }

    .company section, .company section.requests {
        width: 100%;
    }

    .requests .count svg {
        width: 42px;
        height: 42px;
    }

    .products ul {
        text-align: center;
    }

    .products li {
        margin-left: 60px;
        width: 320px;
    }
    .products li:nth-child(2n + 1) {
        margin-left: 0;
    }
}

@media screen and (max-width: 769px) {
    .products li {
        margin-left: 20px;
        min-width: 240px;
    }
    .products li:nth-child(2n + 1) {
        margin-left: 0;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
