.root {
    text-transform: uppercase;
    min-width: auto !important;
}

.root + .root {
    margin-left: 20px;
}

.grow0 {
    flex-grow: 0;
    width: 134px;
}

.grow1 {
    flex-grow: 1;
    width: 180px;
}

@media (max-width: 1024px) {
    .root {
        padding: 0 !important;
    }
    .grow0 {
        flex-grow: 0;
        width: 40%;

    }
    
    .grow1 {
        flex-grow: 1;
        width: 50%;
        margin-left: 10% !important;
    }
}