.root {}
.root tr {
    border-bottom: 2px solid #797d94;
    margin-bottom: 10px;
}
.root th {
    padding: 19px 5px 19px 0;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 14px;
    text-align: left;
}

@media screen and (max-width: 767px) {
    .root tr {
        display: block;
        font-size: 0;
    }
    .root th {
        vertical-align: middle;
        display: inline-block;
        padding: 12px 6px;
        width: 50%;
    }
    .root tr th:first-of-type {
        display: none;
    }
}