.root h2 {
    margin: 48px 0;
    font-size: 3.4rem;
}

.root h3 {
    font-size: 2.8rem;
    margin-bottom: 24px;
}

.root h4 {
    font-size: 2.2rem;
    margin: 32px 0 14px;
}

.subHeaderRight {
    float: right;
}

.subHeaderDropdown {
    float: left;
    width: 235px;
}

.subHeaderDropdown + .subHeaderDropdown {
    margin-left: 10px;
}

.info {
    display: flex;
    min-height: 220px;
    justify-content: space-between;
}

.info .label {
    font-size: 1.6rem;
    line-height: 1.13;
    text-align: center;
    color: #797d94;
    font-weight: 300;
}

.requests {
    display: flex;
    padding: 36px 0;
    width: calc(100% - 33px - 270px);
    background: #fff;
    border-radius: 6px;
}

.countContainer {
    width: calc(270px + 33px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 3.4rem;
    border-right: 1px solid #dcdee6;
}

.typeContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-grow: 1;
}

.countContainer .count {
    margin-bottom: 30px;
}

.countContainer .count svg {
    opacity: 0.4;
    width: 42px;
    height: 42px;
    margin-right: 12px;
    display: inline-block;
    vertical-align: middle;
}

.countContainer .count span {
    display: inline-block;
    vertical-align: middle;
}

.countContainer .count span span {
    margin-left: 5px;
    font-size: 2.2rem;
    vertical-align: bottom;
}

.typeContainer .type {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #797d94;
}

.typeContainer .type + .type {
}

.typeContainer .type.new .circle {
    background-color: #d6f1f4;
    color: #0d7f95;
}

.typeContainer .type.accepted .circle {
    background-color: #d9f5e6;
    color: #18a34e;
}

.typeContainer .type.rejected .circle {
    background-color: #ffd7dc;
    color: #e6374b;
}

.requestResponseTimeSection {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 270px;
    background: #fff;
    border-radius: 6px;
}

.requestResponseTimeSection .countContainer {
    width: 100%;
    border-right: 0;
}

.requestResponseTimeSection .countContainer .count {
}

.circle {
    display: inline-block;
    font-size: 2rem;
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 50%;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
}

.products li {
    display: inline-block;
    vertical-align: top;
    width: 270px;
    margin-bottom: 30px;
    margin-left: 33px;
}

.products h3 {
    margin: 36px 0;
}

.product {
    color: #797d94;
    padding: 24px;
    text-decoration: none;
    display: block;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: none;
    transition: box-shadow 0.3s ease-in;
}

.product:hover {
    color: #797d94;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
}

.product .status {
    text-transform: uppercase;
    border-radius: 16px;
    padding: 5px 16px 7px;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 18px;
}

.product .image {
    display: block;
    max-width: 100%;
    width: 175px;
    height: 175px;
    margin: 12px auto;
    font-size: 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
}

.product h4.productName {
    color: #323647;
    font-size: 1.8rem;
    margin: 8px 0 16px;
    height: 2em;
    overflow: hidden;
}

.product .categoryName {
    font-size: 1.2rem;
}

.product .footer {
    border-top: 1px solid #dcdee6;
    padding-top: 18px;
}

.product .footer span {
    font-size: 1.2rem;
}

.product .footer svg {
    width: 14px;
    height: 14px;
    margin: 0 4px -3px 0;
}

.emptyList {
    margin-bottom: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.emptyList img {
    width: 240px;
    height: auto;
    display: block;
    margin: 0 auto;
}

.emptyList h2 {
    margin: 0 !important;
    font-size: 2.2rem;
    line-height: 30px;
    font-weight: 400;
    color: #b0b3bf;
}

@media screen and (min-width: 1200px) {
    .products li:nth-child(4n + 1) {
        margin-left: 0;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1220px) {
    .products li {
        margin-left: 24px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .countContainer {
        width: 270px;
    }

    .products li {
        margin-left: 60px;
    }
    .products li:nth-child(3n + 1) {
        margin-left: 0;
    }
}

@media screen and (max-width: 991px) {
    .root h2 {
        margin: 78px 0 24px 8px;
        font-size: 2.2rem;
    }

    .root h3 {
        font-size: 2rem;
    }

    .root h4 {
        font-size: 1.8rem;
    }

    .subHeaderRight {
        float: none;
    }

    .subHeaderDropdown {
        float: none;
        margin-top: 10px;
        width: 100%;
        max-width: 100%;
    }

    .subHeaderDropdown + .subHeaderDropdown {
        margin-left: 0;
    }

    .info {
        flex-direction: column;
        width: 100%;
        min-height: auto;
    }

    .requests {
        width: 100%;
        padding: 24px;
        flex-direction: column;
    }

    .countContainer {
        width: 100%;
        padding-bottom: 24px;
        flex-direction: row;
        justify-content: flex-start;
        border-right: 0;
        border-bottom: 1px solid #dcdee6;
    }

    .countContainer .count {
        white-space: nowrap;
        margin-bottom: 0;
        margin-right: 20px;
    }

    .typeContainer {
        flex-direction: column;
    }

    .typeContainer .type {
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 24px;
    }

    .info .label {
        text-align: left;
    }

    .info .label br {
        display: none;
    }

    .circle {
        font-size: 1.4rem;
        width: 45px;
        height: 45px;
        line-height: 45px;
        margin-right: 20px;
        margin-bottom: 0;
    }

    .requestResponseTimeSection {
        padding: 24px;
        margin-top: 16px;
        width: 100%;
    }

    .requestResponseTimeSection .countContainer {
        padding-bottom: 0;
        border-right: 0;
        border-bottom: 0;
    }

    .products ul {
    }

    .products li {
        display: inline-block;
        vertical-align: top;
        margin-left: 0;
        width: calc(50% - 8px);
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .root li:nth-of-type(odd) {
        margin-right: 8px;
    }
    .root li:nth-of-type(even) {
        margin-left: 8px;
    }
}

@media screen and (max-width: 767px) {
    .products li {
        display: block;
        width: 100%;
        max-width: calc(320px - 40px);
        margin-left: auto;
        margin-right: auto;
    }
}
