.root {}

.dropdownHolder {
    width: 200px;
    margin-right: 15px;
}
.searchHolder {
    float: right;
    width: 300px;
    position: relative;
}

.searchHolder > svg {
    display: none;
    position: absolute;
    cursor: pointer;
    width: 30px;
    height: 30px;
    right: 0;
    bottom: calc(100% + 10px);
}

.addNewEntry {
    float: right;
}

.addNewEntry span {
    text-transform: uppercase;
    font-weight: 400;
}
.addNewEntry svg {
    width: 30px;
}
.addNewEntry :global(.svgFill1),
.addNewEntry :global(.svgFill2) {
    fill: #fff;
}

.emptyList {
    height: calc(100vh - 300px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.emptyList img {
    width: 240px;
    height: auto;
    display: block;
    margin: 0 auto;
}
.emptyList h2 {
    margin: 0;
    font-size: 2.2rem;
    line-height: 30px;
    font-weight: 400;
    color: #b0b3bf;
}

.nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

@media screen and (max-width: 639px) {
    .searchHolder {
        clear: both;
        float: none;
        width: 100%;
    }
    .searchHolder > svg {
        display: block;
    }
    .searchHolder > div {
        display: none;
    }
    .searchHolder > div.isSearchShown {
        display: block;
    }
}

@media screen and (max-height: 500px) {
    .emptyList img {
        width: 150px;
    }
}

@media screen and (max-width: 479px) {
    .addNewEntry {
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        bottom: 24px;
        z-index: 200;
    }

    .nav {
        margin-top: 15px;
        justify-content: space-between;
        width: 100%;
    }
}