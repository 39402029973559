.header {
    position: relative;
    padding: 34px 54px 0 54px;
}

.close {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -15px;
    width: 29px;
    height: 29px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
}

@media screen and (max-width: 767px) {
    .body {
        padding: 15px 25px 0 25px;
    }
}