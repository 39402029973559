.root {
    margin: 0;
    width: 100%;
    max-width: none;
}

.root + .root {
    margin-top: 20px;
}

.title {
}

.content {
}

.icon {
}

.close {
}
