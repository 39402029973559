.root {
    margin-top: 32px;
}
.root .emptyList {
    text-align: center;
}
.user {
    position: relative;
    width: 100%;
    height: 96px;
    border-radius: 6px 6px;
    background-color: #fff;
    padding: 24px;
    margin-bottom: 16px;
    transition: 0.3s box-shadow ease-in-out;
}

.user:hover {
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
}

.sideWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.leftSide, .rightSide {
    display: flex;
    align-items: center;
}

.user .leftSide {
    margin-left: 65px;
    flex-direction: column;
    align-items: flex-start;
}

.signature {
    position: absolute;
    width: 49px;
    height: 49px;
    border-radius: 50% 50%;
    background-color: #adb2bd;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 500;
    color: #fff;
    line-height: 49px;
}

.name {
    font-size: 1.8rem;
    line-height: 1.33;
    color: #323647;
}

.edit a,
.delete,
.email {
    display: flex;
    align-items: center;
}

.edit,
.delete {
    margin-left: 24px;
}

.edit a,
.delete {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.29;
    color: #ff4056;
}

.edit a svg,
.delete svg {
    width: 30px;
    height: 30px;
}

.edit a span,
.delete span {
    display: inline-block;
    margin-left: 5px;
}

.edit a:hover,
.delete:hover {
    cursor: pointer;
    color: #ff4056;
}

.email {
    border-right: 1px solid #dcdee6;
    padding: 0 23px 0 12px;
    font-size: 1.6rem;
    line-height: 1.13;
    color: #797d94;
}

.email svg {
    margin-right: 6px;
    width: 22px;
    height: 22px;
    stroke: #f2f3f7;
}

.email_mobile {
    display: none;
}

@media screen and (max-width: 991px) {
    .root {
        margin-bottom: 100px;
    }
    .user .leftSide {
        display: block;
        width: calc(100% - 170px);
    }
    .email {
        display: none;
    }
    .email_mobile {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .edit a span, .delete span {
        display: none;
    }
}

@media screen and (max-width: 479px) {
    .user {
        padding: 18px;
    }
    .name {
        font-size: 1.4rem;
    }
    .edit,
    .delete {
        margin-left: 5px;
    }
}

@media screen and (max-width: 400px) {
    .user .leftSide {
        margin-left: 0;
        width: calc(100% - 100px);
    }
    .signature {
        display: none;
    }
}
