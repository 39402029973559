.categories h3 {
    margin: 0;
    padding: 0;
    padding-bottom: 24px;
    font-size: 2.8rem;
    font-weight: 300;
    line-height: 1.14;
    color: #323647;
}

.emptyList {
    margin: 0 0 10px 0;
}

.category {
    display: flex;
    justify-content: space-between;
}

.category .name {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}
.category {
    display: flex;
    align-items: center;
    height: 36px;
    line-height: 48px;
}
.category:hover {
    cursor: pointer;
}
.category .name svg {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    stroke: #797d94;
}
.shownSubcategory .category .name svg {
    transform: rotate(180deg);
}
.subcategories {
    width: 100%;
    margin: 8px 0;
    display: none;
}

.shownSubcategory .subcategories {
    display: block;
}

.subcategory td {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
}

.subcategory td:nth-child(1) {
    padding: 12px !important;
    font-weight: 300 !important;
    font-size: 1.6rem !important;
}

.category .delete,
.subcategory .delete {
    min-width: 144px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #ff4056;
    line-height: 1.29;
    font-size: 1.4rem;
    font-weight: 500;
    white-space: nowrap;
}

.category .delete:hover,
.subcategory .delete:hover {
    cursor: pointer;
}

.category .delete svg {
    width: 30px;
    height: 30px;
}

.subcategory .delete {
    min-width: 50px;
    font-size: 1.2rem;
    line-height: 1.33;
}

.subcategory .delete svg {
    width: 24px;
    height: 24px;
}

.add {
    display: flex;
    width: 100%;
    margin: 36px 0 0;
    justify-content: flex-end;
}

.add svg g path {
    fill: #ff4056;
}

.emptyList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #dcdee6;
    padding-top: 23px;
    margin-top: 10px;
}

.emptyList .message {
    width: 100%;
    display: flex;
    align-items: center;
}

.emptyList svg {
    margin-right: 6px;
    width: 30px;
    height: 30px;
}

.emptyList .add {
    margin-top: 0px;
}

@media screen and (max-width: 479px) {
    .category .delete {
        font-size: 1.2rem;
        margin-right: -10px;
    }
    .category .delete svg {
        width: 24px;
        height: 24px;
    }
    .subcategory .delete {
        font-size: 1.1rem;
        margin-right: 0;
    }
    .subcategory .delete svg {
        width: 20px;
        height: 20px;
    }
}
