.root {
    text-transform: uppercase;
}

.cancel {
    flex-grow: 0;
}

.submit {
    flex-grow: 1;
    margin-left: 15px;
}

@media (min-width: 768px) {
    .root {
        min-width: auto !important;
    }
}

@media (max-width: 767px) {
    .root {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .submit {
        margin-left: 0;
        order: 1;
    }

    .cancel {
        margin-top: 10px;
        order: 2;
    }
}