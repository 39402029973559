.root {

}

.root :global(.row) + :global(.row) {
    margin-top: 20px;
}

.form {
    max-width: 372px;
    margin: 0 auto;
}

.actions {
    margin-top: 35px;
}

.forgotPassword {
    margin-top: 25px;
    text-align: center;
    font-size: 14px;
}

.forgotPassword .link {
    cursor: pointer;
    color: #00a5ff;
}

.forgotPassword .link:hover {
    opacity: 0.8;
}

@media screen and (max-width: 767px) {
    .form {
        max-width: 100%;
    }
}