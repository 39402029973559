.close {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    z-index: 401;
    position: absolute;
    top: 45px;
    right: 45px;
    cursor: pointer;
    background: #fff;
}

.close:hover svg {
    opacity: 1;
    transition: .3s
}

.close svg {
    margin: 3px 0 0 3px;
    transform: rotate(45deg) scale(1.2);
    opacity: .8;
    transition: .3s
}

.close.mediumSmall {
    width: 36px;
    height: 36px;
    top: 15px;
    right: 16px;
}
