.goBack-container {
    position: relative;
    z-index: 10;
    padding: 20px 0;
    border-bottom: 2px solid #dcdee6;
}
.goBack {
    text-decoration: none;
    padding: 5px 0;
    clear: both;
}
.goBack svg {
    display: inline-block;
    vertical-align: middle;
    width: 12px;
    height: 14px;
    margin-right: 12px;
}
.goBack svg path {
    fill: #c0c2cc;
}
.goBack span {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.8rem;
    line-height: 22px;
    color: #323647;
    transition: 0.3s ease-in-out;
    opacity: 1;
}
.goBack:hover span {
    opacity: 0.8;
    transition: 0.3s ease-in-out;
}
.error-notification {
    position: relative;
    margin: 0 auto 24px;
    max-width: 852px;
    padding: 20px 82px;
    border-radius: 12px;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
}
.error-notification p:first-of-type {
    font-size: 16px;
    font-weight: 600;
    color: #ff4056;
    margin: 0 0 4px;
}
.error-notification p:last-of-type {
    font-size: 14px;
    color: #484b61;
    margin: 0;
}
.error-notification svg:first-of-type {
    position: absolute;
    top: 15px;
    left: 16px;
}
.error-notification svg:last-of-type {
    position: absolute;
    top: 24px;
    right: 16px;
    transform: rotate(45deg);
    cursor: pointer;
    opacity: 0.5;
}

.gallery-error {
    position: relative;
    margin: 10px 0 25px;
    padding: 6px 70px;
    border: 1px solid #ffabb4;
    border-radius: 4px;
    background-color: #fff;
}
.gallery-error svg {
    position: absolute;
    top: 10px;
    left: 16px;
}
.gallery-error p {
    font-size: 14px;
    font-weight: 600;
    color: #ff4056;
    margin: 15px 0;
    height: auto !important;
}

.container h2 {
    display: block;
    margin: 40px auto 10px;
    max-width: 852px;
    font-size: 34px;
    color: #323647;
    font-weight: 400;
}

.root {
    width: 100%;
    margin: 0 auto 120px auto;
}

.root .loader-background {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 852px;
    height: 300px;
    background: #fff;
    border-radius: 12px;
}

.form section textarea:-ms-input-placeholder {
    color: #b0b3bf;
    transition: 0;
}

.form section:first-of-type > div:first-of-type {
    margin: 66px 0 7px;
}
.form section:first-of-type .line-cover > div {
    float: left;
}
.form section:first-of-type .line-cover > p {
    font-size: 14px;
    font-weight: 500;
    color: #797d94;
    margin: 26px 0 20px 0;
}
.form section:first-of-type > div:nth-of-type(2) > div:nth-of-type(2) {
    margin: 0 15px 20px;
}
.form section:first-of-type > div:nth-of-type(2) > div:nth-of-type(3) {
    float: left;
    width: 150px;
    position: relative;
    top: -8px;
}
.form section:first-of-type > div:nth-of-type(3) > p {
    margin: 15px 0 20px 0;
}
.form section:first-of-type > div:nth-of-type(3) > div:nth-of-type(3) {
    position: relative;
    top: -8px;
}
.form
    section:first-of-type
    > div:nth-of-type(2)
    > div:nth-of-type(3)
    > div:nth-last-of-type(4) {
    position: relative;
    left: -175px;
    top: -11px;
    width: 250px;
}
.form
    section:first-of-type
    > div:nth-of-type(2)
    > div:nth-of-type(3)
    > div:last-of-type {
    width: 150px;
    min-width: 150px;
}
.form section:first-of-type .line-cover > div:nth-of-type(2) {
    margin: 0 15px 20px;
}
.form section:first-of-type > div:nth-of-type(4) {
    margin: 0 0 64px;
    padding-bottom: 23px;
}
.form section:first-of-type > div:nth-of-type(5) textarea {
    min-height: 172px;
}
.form section:first-of-type > div:nth-of-type(6) textarea {
    min-height: 72px;
    margin-top: 51px;
}
.form section:first-of-type > div:nth-of-type(7) {
    margin-bottom: 66px;
    padding-bottom: 36px;
}

.form section:nth-of-type(2) {
    min-height: 324px;
}
.form section:nth-of-type(2) h3 {
    margin-bottom: 0px;
}
.form section:nth-of-type(2) h4 {
    margin: 6px 0 20px;
}

.form section:nth-of-type(2) > span {
    margin-bottom: 24px;
}
.form section:nth-of-type(2) span > div {
    border: 5px solid #ff4056;
    border-right-color: transparent;
}
.form section:nth-of-type(2) .gallery-error p {
    height: 34px;
}
.form section:nth-of-type(3) h3 {
    margin-bottom: 66px;
}
.form section:nth-of-type(3) > div:first-of-type,
.form section:nth-of-type(3) > div:nth-of-type(5),
.form section:nth-of-type(3) > div:nth-of-type(6),
.form section:nth-of-type(3) > div:nth-of-type(7) {
    width: 274px;
}
.form section:nth-of-type(3) > div:nth-of-type(3) {
    margin-top: 39px;
}
.form section:nth-of-type(3) > div:nth-of-type(4) {
    margin-top: 23px;
}
.form section:nth-of-type(3) .line-cover > div {
    float: left;
}
.form section:nth-of-type(3) .line-cover:after {
    content: '';
    display: table;
    clear: both;
}
.form section:nth-of-type(3) > div:nth-of-type(5),
.form section:nth-of-type(3) > div:nth-of-type(6),
.form section:nth-of-type(3) > div:nth-of-type(7) {
    margin-top: 52px;
}

.form section:nth-of-type(4) h4 {
    display: inline-block;
    margin: 32px 0 26px;
    font-size: 18px;
}
.form section:nth-of-type(4) h5 {
    display: inline-block;
    margin-bottom: 28px;
    font-size: 14px;
    font-weight: normal;
    color: #797d94;
}
.form section:nth-of-type(4) .line-cover > div,
.form section:nth-of-type(5) .line-cover > div {
    float: left;
}
.form section:nth-of-type(4) .line-cover:after,
.form section:nth-of-type(5) .line-cover:after {
    content: '';
    display: table;
    clear: both;
}
.form section:nth-of-type(4) .line-cover > div:first-of-type {
    width: 374px;
}
.form section:nth-of-type(4) .line-cover > div:nth-of-type(2),
.form section:nth-of-type(5) .line-cover > div:nth-of-type(2) {
    width: 274px;
    margin-left: 32px;
}
.form
    section:nth-of-type(4)
    .line-cover
    > div:nth-of-type(2)
    > div:nth-of-type(3) {
    position: relative;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.form
    section:nth-of-type(4)
    .line-cover
    > div:nth-of-type(2)
    > div:nth-of-type(3):after {
    position: absolute;
    content: '';
    display: block;
    width: 1px;
    height: 48px;
    top: -1px;
    left: auto;
    right: -1px;
    background: #b0b3bf;
    opacity: 0;
    transition: 0.3s ease-in-out;
    z-index: 2;
}
.form
    section:nth-of-type(4)
    .line-cover
    > div:nth-of-type(2):hover
    > div:nth-of-type(3):after {
    opacity: 1;
    transition: 0.3s ease-in-out;
}

.form section:nth-of-type(4) > div:nth-of-type(2),
.form section:nth-of-type(5) > div:nth-of-type(2) {
    padding-bottom: 31px;
    margin-bottom: 0;
}
.form section:nth-of-type(4) .line-cover > div:last-of-type {
    width: 100px;
}
.form section:nth-of-type(4) .line-cover > div:last-of-type > div:nth-last-of-type(2) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.form section:nth-of-type(4) .line-cover > div:last-of-type > div:last-of-type {
    min-width: 100%;
}
.form section:nth-of-type(4) > div:last-of-type > div,
.form section:nth-of-type(5) > div:last-of-type > div {
    float: left;
    width: 30%;
    max-width: 109px;
    margin-right: 41px;
    margin-bottom: 0px;
}
.form section:nth-of-type(4) > div:last-of-type > div input,
.form section:nth-of-type(5) > div:last-of-type > div input {
    width: calc(100% - 37px);
    padding: 8px;
}
.form section:nth-of-type(4) > div:last-of-type:after,
.form section:nth-of-type(5) > div:last-of-type:after {
    content: '';
    display: table;
    clear: both;
}
.form section:nth-of-type(4) > div:last-of-type > div:nth-of-type(2),
.form section:nth-of-type(5) > div:last-of-type > div:nth-of-type(2) {
    position: relative;
}
.form section:nth-of-type(4) > div:last-of-type > div:nth-of-type(2):before,
.form section:nth-of-type(4) > div:last-of-type > div:nth-of-type(2):after,
.form section:nth-of-type(5) > div:last-of-type > div:nth-of-type(2):before,
.form section:nth-of-type(5) > div:last-of-type > div:nth-of-type(2):after {
    content: 'X';
    font-size: 14px;
    position: absolute;
    top: 15px;
    color: #b0b3bf;
}
.form section:nth-of-type(4) > div:last-of-type > div:nth-of-type(2):before,
.form section:nth-of-type(5) > div:last-of-type > div:nth-of-type(2):before {
    left: -25px;
}
.form section:nth-of-type(4) > div:last-of-type > div:nth-of-type(2):after,
.form section:nth-of-type(5) > div:last-of-type > div:nth-of-type(2):after {
    right: -25px;
}

.form section:nth-of-type(5) h3 {
    display: inline-block;
    white-space: pre;
}
.form section:nth-of-type(5) h4 {
    display: inline-block;
}
.form section:nth-of-type(5) .line-cover > div:first-of-type,
.form section:nth-of-type(5) .line-cover > div:nth-of-type(2),
.form section:nth-of-type(5) .line-cover > div:last-of-type {
    width: 238px;
}
.form section:nth-of-type(5) .line-cover > div:nth-of-type(2) {
    margin-right: 33px;
}
.form
    section:nth-of-type(5)
    .line-cover
    > div:nth-of-type(2)
    > div:nth-of-type(2) {
    border: 1px solid #dcdee6;
    border-radius: 4px;
}
.form section:nth-of-type(5) .line-cover {
    margin-top: 20px;
}
.form section:nth-of-type(5) > div:nth-of-type(2) {
    margin-bottom: 37px;
}

.form section:nth-of-type(6) > h3 {
    margin-bottom: 22px;
}
.form section:nth-of-type(6) > div:first-of-type {
    display: block;
    float: left;
    width: 238px;
    margin-right: 32px;
}
.form section:nth-of-type(6) > div:first-of-type > div:nth-of-type(5),
.form section:nth-of-type(6) > div:last-of-type > div:last-of-type {
    top: -230px;
}
.form section:nth-of-type(6) > div:first-of-type > div:nth-of-type(5) > div,
.form section:nth-of-type(6) > div:last-of-type > div:nth-of-type(5) > div {
    overflow-x: auto;
}
.form section:nth-of-type(6) > div:first-of-type > div:nth-last-of-type(2) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.form section:nth-of-type(6) > div:nth-of-type(2) {
    float: left;
    width: calc(100% - 270px);
}
.form section:nth-of-type(6) > span {
    display: block;
    clear: both;
}
.form section:nth-of-type(6) > div:last-of-type {
    display: block;
    margin-top: 54px;
    width: 238px;
}

.actions {
    margin-top: 30px;
}

.messageSuccess {
    border-radius: 6px;
    background-color: #baedcd;
    padding: 8px 15px;
    color: #18a34e;
    margin: 20px;
}

@media screen and (max-width: 991px) {
    .form section:nth-of-type(2) {
        min-height: 350px;
    }
    .form section:nth-of-type(4) .line-cover > div:first-of-type {
        width: 50%;
    }
    .form section:nth-of-type(4) .line-cover > div:nth-of-type(2) {
        width: calc(50% - 132px);
    }
    .root .form section:nth-of-type(5) .line-cover > div {
        width: 184px;
    }
}

@media screen and (max-width: 767px) {
    .goBack-container {
        z-index: 10;
        padding: 0;
        padding-top: 1px;
        border-bottom: none;
    }
    .goBack {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        z-index: 5;
        background-color: #fff;
        display: block;
        padding: 15px 16px;
    }
    .root {
        width: 100%;
        max-width: 100%;
    }
    .container h2 {
        margin: 80px auto 8px;
        padding-left: 8px;
        font-size: 22px;
    }
    .root .form section > h4 {
        font-size: 16px;
    }

    .form section:first-of-type > div:first-of-type {
        margin: 55px 0 7px;
    }
    .form section:first-of-type .line-cover > div:first-of-type {
        margin-top: 58px;
    }
    .form section:first-of-type > div:nth-of-type(3) > div:first-of-type {
        margin-top: 38px;
    }
    .form section:first-of-type > div:nth-of-type(2) > div:last-of-type {
        margin-top: 22px;
    }
    .form section:first-of-type .line-cover > div:last-of-type {
        width: 100%;
        display: block;
    }

    .form section:first-of-type > div:nth-of-type(2) > div:first-of-type {
        margin-top: 0;
    }
    .form section:first-of-type > div:nth-of-type(2) > div:nth-of-type(2) {
        width: 70px;
        margin-top: 0;
    }

    .form section:first-of-type > div:nth-of-type(2) > div:nth-of-type(3) {
        width: 100%;
        margin: 6px 0 3px;
    }

    .form section:first-of-type > div:nth-of-type(3) > div:first-of-type {
        margin-top: 0;
    }
    .form section:first-of-type > div:nth-of-type(3) > div:nth-of-type(2) {
        width: 70px;
    }
    .form section:first-of-type > div:nth-of-type(3) > div:nth-of-type(3) {
        width: 100%;
        margin-top: 6px;
    }
    .form
        section:first-of-type
        > div:nth-of-type(2)
        > div:nth-of-type(3)
        > div:last-of-type {
        width: 100%;
    }

    .form section:first-of-type > div:nth-of-type(4) {
        margin: 1px 0 64px;
        padding-bottom: 18px;
    }
    .form section:first-of-type > div:nth-of-type(5) textarea {
        min-height: 122px;
    }
    .form section:first-of-type > div:nth-of-type(6) {
        margin-top: 42px;
        margin-bottom: 6px;
    }

    .form section:nth-of-type(2) {
        min-height: 278px;
    }

    .form section:nth-of-type(3) h3 {
        margin-bottom: 55px;
    }
    .root .form section:nth-of-type(3) > div,
    .root .form section:nth-of-type(3) .line-cover > div {
        width: 100%;
    }
    .form section:nth-of-type(3) > div:nth-of-type(2) {
        padding-bottom: 25px;
        margin-bottom: 26px;
    }
    .form section:nth-of-type(3) > div:nth-of-type(3) {
        margin-top: 0;
    }
    .form section:nth-of-type(3) .line-cover div:first-of-type {
        margin-bottom: 24px;
    }
    .form section:nth-of-type(3) > div:nth-of-type(5) {
        margin-top: 50px;
    }
    .form section:nth-of-type(3) > div:nth-of-type(6),
    .form section:nth-of-type(3) > div:last-of-type {
        margin-top: 54px;
    }

    .form section:nth-of-type(4) h4 {
        line-height: 1.25;
        margin: 6px 0 30px;
    }
    .form section:nth-of-type(4) .line-cover > div:first-of-type {
        width: 100%;
    }
    .form section:nth-of-type(4) .line-cover > div:nth-of-type(2) {
        width: calc(100% - 100px);
        margin: 54px 0 0 0;
    }
    .form section:nth-of-type(4) .line-cover > div:last-of-type {
        margin: 54px 0 0 0;
    }
    .form section:nth-of-type(4) > div:nth-of-type(2) {
        padding-bottom: 23px;
    }

    .form section:nth-of-type(4) > div:nth-of-type(3) > div,
    .form section:nth-of-type(5) > div:nth-of-type(3) > div {
        width: 28%;
        max-width: 200px;
    }
    .form section:nth-of-type(4) > div:nth-of-type(3) input,
    .form section:nth-of-type(5) > div:nth-of-type(3) input {
        padding: 12px 5px;
        opacity: 0.9;
        text-align: center;
    }
    .form section:nth-of-type(4) > div:nth-of-type(3) span,
    .form section:nth-of-type(5) > div:nth-of-type(3) span {
        padding-left: 4px;
        right: 5px;
    }
    .form section:nth-of-type(4) > div:nth-of-type(3) > div:first-of-type,
    .form section:nth-of-type(4) > div:nth-of-type(3) > div:nth-of-type(2),
    .form section:nth-of-type(5) > div:nth-of-type(3) > div:first-of-type,
    .form section:nth-of-type(5) > div:nth-of-type(3) > div:nth-of-type(2) {
        margin-right: 8%;
    }
    .form section:nth-of-type(4) > div:nth-of-type(3) > div:last-of-type,
    .form section:nth-of-type(5) > div:nth-of-type(3) > div:last-of-type {
        margin-right: 0;
    }
    .form section:nth-of-type(4) > div:last-of-type > div:nth-of-type(2):before,
    .form
        section:nth-of-type(5)
        > div:last-of-type
        > div:nth-of-type(2):before {
        left: -18%;
    }
    .form section:nth-of-type(4) > div:last-of-type > div:nth-of-type(2):after,
    .form section:nth-of-type(5) > div:last-of-type > div:nth-of-type(2):after {
        right: -18%;
    }

    .form section:nth-of-type(5) h3 {
        margin: 2px 0 8px 0;
    }
    .root .form section:nth-of-type(5) .line-cover > div {
        width: 100%;
        margin-left: 0;
    }
    .form section:nth-of-type(5) .line-cover > div:nth-of-type(2) {
        margin-top: 54px;
    }
    .form section:nth-of-type(5) .line-cover > div:last-of-type {
        margin-top: 52px;
        margin-bottom: 6px;
    }
    .form section:nth-of-type(5) > div:nth-of-type(2) {
        margin-bottom: 28px;
    }

    .root .form section:nth-of-type(6) > div {
        width: 100%;
        margin-right: 0;
    }
    .form section:nth-of-type(6) h3 {
        margin-bottom: 28px;
    }
    .form section:nth-of-type(6) > div:nth-of-type(2) {
        margin-top: 54px;
    }
}
