.root {
    border-radius: 6px;
    background-color: #ffffff;
    padding: 36px;
    margin: 0 auto;
    margin-bottom: 24px;
    max-width: 852px;
}

.root h3 {
    margin: 0;
    font-size: 28px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: #323647;
}

.root h4 {
    margin: 0;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #484b61;
}

@media screen and (max-width: 767px) {
    .root {
        padding: 24px;
        margin-bottom: 16px;
    }
    .root h3 {
        font-size: 20px;
        margin-bottom: 20px;
    }
}
