.root > h2 {
    padding: 35px 0;
    margin-bottom: 0;
    font-size: 3.4rem;
    font-weight: 300;
    color: #323647;
}

.root h3 {
    font-size: 2.8rem;
    font-weight: normal;
    line-height: 1.14;
    color: #323647;
    margin: 0 0 25px;
}

.root h4 {
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.36;
  color: #323647;
  margin: 10px 0;
}

.content {
    display: flex;
    justify-content: space-between;
}

.content section {
    background-color: #ffffff;
    border-radius: 6px;
}

.actions {
    width: 271px;
    height: 403px;
}

.actions .action {
    display: flex;
    align-items: center;
    width: calc(100% - 48px);
    margin: 0 24px;
    padding: 12px;
    border-top: 1px solid #dadde5;
    color: #797d94;
}

.actions .action:hover {
    color: #ff4056;
    cursor: pointer;
}

.actions svg {
    width: 30px;
    height: 30px;
    margin-right: 8px;
}

.action svg * {
    fill: #c0c2cc;
}

.action:hover svg * {
    fill: #ff4056;
}

.actions .avatar {
    display: flex;
    width: 170px;
    height: 170px;
    margin: 50px auto;
    border-radius: 50% 50%;
    font-size: 6rem;
    font-weight: 500;
    background-color: #adb2bd;
    color: #fff;
    justify-content: center;
    align-items: center;
}

.summary {
    width: calc(100% - 271px);
    margin-left: 40px;
}

.summary section {
    padding: 36px;
    margin-bottom: 24px;
}

.summary p {
    font-size: 16px;
    line-height: 1.5;
}

.summary h4 {
    font-size: 2.2rem;
    margin: 32px 0 14px;
}

.category li {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.category {
    display: flex;
    align-items: center;
    height: 36px;
    line-height: 48px;
}
.category:hover {
    cursor: pointer;
}
.category svg {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    stroke: #797d94;
}
.shownSubcategory .category svg {
    transform: rotate(180deg);
}
.subcategories {
    width: 100%;
    margin-top: 8px;
    display: none;
}

.shownSubcategory .subcategories {
    display: block;
}

@media screen and (max-width: 1024px) {
    .root h2 {
        padding: 24px 0;
        margin: 0;
        font-size: 2.2rem;
        font-weight: normal;
        line-height: 1.18;
        color: #323647;
    }
    .root h3 {
        font-size: 2rem;
    }

    .root h4 {
        font-size: 1.8rem;
        font-weight: normal;
        line-height: 1.22;
        color: #323647;
    }

    .content {
        display: block;
        width: 100%;
    }

    .actions {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-around;
        padding: 24px 0;
    }

    .actions .avatar {
        display: none;
    }

    .actions .action {
        justify-content: center;
        margin: 0;
        padding: 0;
        border: none;
    }

    .actions .action:last-child {
        border-left: 1px solid #dadde5;
    }

    .content .summary {
        width: 100%;
        margin-left: 0;
    }

    .content .summary section {
        margin-top: 16.5px;
        padding: 24px;
    }
}
