.root {
    background: #fff;
}

.icon {
}

.icon img {
    margin: 0 auto;
    max-width: 240px;
    width: 100%;
    display: block;
}

.message {
    text-align: center;
    line-height: 1.5;
}

.actions {
    margin-top: 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.actions.center {
    justify-content: center;
}

@media (max-width: 767px) {
    .actions {
        flex-direction: column;
        align-items: center;
    }
}
