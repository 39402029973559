.header {
    max-width: 852px;
    height: 223px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.header h2 {
    width: 550px;
    line-height: 225px;
    margin: 0;
    font-weight: normal;
    color: #323647;
    font-size: 34px;
    color: #323647;
}
.header svg {
    margin-top: -7px;
    transform: scale(.667)
}
.header:after {
    content: '';
    display: table;
    clear: both;
}

.root form > div:first-of-type,
.root form > div:nth-of-type(2)
.root form > button {
    display: block;
    float: left;
}
.root form button:after {
    content: '';
    display: table;
    clear: both;
}
.root .section-container {
    padding-bottom: 8px;
}

.root .section-container h3 {
    margin-top: 6px;
    text-align: left;
    font-weight: normal;
    font-size: 22px;
}
.root .section-container > span {
    width: 100px;
    height: 100px;
    display: block;
    margin: 0 auto;
}

@media screen and (max-width: 991px) {
    .header svg {
        margin-left: -40px;
    }
}

@media screen and (max-width: 767px) {
    .header {
        position: relative;
        display: block;
        height: 208px;
    }
    .header svg {
        position: absolute;
        display: block;
        margin: 0 auto;
        top: -28px;
        left: 0;
        right: 0;
        transform: scale(.583)
    }
    .header h2 {
        width: 100%;
        text-align: center;
        line-height: auto;
        font-size: 20px;
        padding-top: 53px;
    }
}