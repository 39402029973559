.root {
    color: #484b61;
}
.root tr {
    width: 100%;
}
.root tr:nth-of-type(odd) {
    border-top: 1px solid #dcdee6;
    border-bottom: 1px solid #dcdee6;
    background-color: #f2f3f7;
}
.root tr:first-of-type {
    border-top: 0;
}
.root tr:nth-of-type(even) {
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
}
.root td {
    padding: 12px 5px 12px 0;
    font-size: 1.4rem;
    line-height: 18px;
}
.root tr td:first-of-type {
    padding-left: 16px;
    font-size: 1.4rem;
    font-weight: 600;
    color: #272b39;
}

@media screen and (max-width: 767px) {
    .root tr {
        font-size: 0;
        display: block;
    }
    .root tr td:first-of-type {
        display: block;
        width: 100%;
        padding: 6px 16px 2px;
        font-size: 1.2rem;
    }
    .root td {
        display: inline-block;
        vertical-align: middle;
        padding: 0 16px 6px;
        width: 50%;
        font-size: 1.4rem;
        line-height: 18px;
    }
}