.root {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    pointer-events: none;
}

.root h3 {
    position: absolute;
    border-radius: 25px;
    padding: 10px 25px;
    font-size: 1.8rem;
    font-weight: 300;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    color: #333;
    bottom: -50px;
    z-index: 2;
    max-width: calc(100% - 30px);   
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
}
.root h3:empty {
    display: none;
}
.loader-cover {
    background: blue;
    width: 100px;
    height: 100px;
}
.loader {
    position: relative;
    top: 50%;
    left: 50%;
}

.left,
.right {
    width: 34px;
    height: 76px;
    position: absolute;
    padding-left: 10px;
    padding-top: 25px;
    top: calc(50% - 27px);
    left: 15px;
    right: auto;
    margin-top: -11px;
    cursor: pointer;
    z-index: 10;
    border-radius: 17px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    background: #fff;
    opacity: .5;
    transition: .3s;
    pointer-events: auto;
}
.left.isDisabledGalleryButton,
.right.isDisabledGalleryButton {
    pointer-events: none;
    opacity: .2
}
.left:hover,
.right:hover {
    transition: .3s;
    opacity: 1;
}
.left.isDisabledGalleryButton:hover,
.right.isDisabledGalleryButton:hover {
    transition: .3s;
    opacity: .1;
}
.right {
    padding-left: 12px;
    right: 15px;
    left: auto;
}
.left:before,
.left:after,
.right:before,
.right:after {
    content: '';
    display: block;
    margin-top: 16px;
    width: 14px;
    height: 2px;
    transform: rotate(135deg);
    background: #ff4056;
    border-radius: 2px;
}
.left:before {
    transform: rotate(45deg);
}
.right:after {
    margin-top: -11px;
    transform: rotate(45deg);
}
.left:after {
    margin-top: -11px;
    transform: rotate(135deg);
}

.slick {
    width: 100%;
    height: 100%;
    margin: 0 auto;
}
.slick :global(.slick-arrow) {
    width: 34px;
    height: 75px;
    border-radius: 17px;
    z-index: 2;
    opacity: 0.5;
    transition: opacity .3s ease-in;
}
.slick :global(.slick-prev) {
    left: 15px;
}
.slick :global(.slick-next) {
    right: 15px;
}
.slick.isDisabledGalleryButton :global(.slick-prev),
.slick.isDisabledGalleryButton :global(.slick-next) {
    pointer-events: none;
}
.slick :global(.slick-prev)::before,
.slick :global(.slick-next)::before {
    display: none;
}
.slick :global(.slick-next:focus),
.slick :global(.slick-prev:focus) {
    background: #fff;
} 
.slick :global(.slick-prev:hover),
.slick :global(.slick-next:hover) {
    background: #fff;
    opacity: 1;
}
.slick :global(.slick-prev:hover) .left {
    height: 220px !important;
}

.slick div {
    outline: none;
    position: relative;
    height: 100%;
}
.slick > div > div {
    width: 100% !important;
}

.image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
}

.root .image-count {
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    width: 50px;
    height: 24px;
    margin: 5px auto;
    line-height: 24px;
    font-size: 14px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    background: #fff;
}