.main {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    min-height: calc(100vh - 132px);
}

@media screen and (max-width: 991px) {
    .main {
        min-height: auto;
    }
}