.dialog {
    position: relative;
    display: inline-block;
    margin: 100px auto 15px;
    background: #fff;
    text-align: left;
    border-radius: 12px;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 767px) {
    .dialog {
        margin: 35px 15px;
        width: calc(100% - 30px) !important;
    }
}
