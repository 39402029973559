.root {
    border-radius: 6px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    overflow: hidden;
}

.summary {
    position: fixed;
    text-align: center;
    width: 271px;
    top: 133px;
}

.summary h3 {
    font-size: 2.2rem;
    line-height: 30px;
    font-weight: 300;
    margin: -16px 0 16px;
}
.summary p {
    font-size: 1.2rem;
    line-height: 18px;
    color: #797d94;
}

.summary hr {
    margin: 24px 0 32px;
    display: block;
    height: 1px;
    background-color: #dcdee6;
    border: 0;
    outline: 0;
}

.isManufacturer.isDraft .content a {
    color: #ff4056;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 30px;
    vertical-align: top;
}

.isManufacturer.isDraft .content a svg {
    width: 30px;
    height: 30px;
    margin-right: 6px;
    display: inline-block;
    vertical-align: top;
}

.isManufacturer.isDraft .content a svg path {
    fill: currentColor;
}

.header {
    text-align: center;
    padding: 19px 5px;
    font-size: 1.2rem;
    line-height: 14px;
}

.header h2 {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 18px;
    text-transform: uppercase;
    margin: 5px 0 0;
}

.mobile {
    display: none;
}

.content {
    padding: 0 24px 32px 24px;
    max-width: 376px;
    margin: 0 auto;
}

.content .icon img {
    max-width: 100%;
}

.actions .button:not(:first-of-type) {
    margin-top: 25px;
}

@media screen and (min-width: 992px) {
    .isManufacturer.isDraft .content p {
        margin: 8px 0 24px;
    }

    .isManufacturer.isDraft .content {
        padding-top: 32px;
    }

    .isSalesman.isNew .content {
        padding-top: 43px;
        padding-bottom: 40px;
    }

    .isSalesman.isNew .holder p {
        margin: 20px 0 45px;
    }

    .isNew .actions,
    .isAccepted .actions {
        margin-top: 24px;
    }
}

@media screen and (min-width: 1200px) {
    .summary {
        left: calc(50% + 319px);
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .summary {
        width: 250px;
        left: calc(50% + 215px);
    }
}

@media screen and (min-width: 768px) and (max-height: 639px) {
    .header {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .summary {
        top: auto;
        bottom: 15px;
    }
}

@media screen and (max-width: 991px) {
    .summary {
        position: static;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
    }
    .root {
        box-shadow: 0 -10px 30px 0 rgba(0, 0, 0, 0.1);
        border-radius: 0;
    }

    .content {
        padding: 0;
    }

    .content .icon img {
        max-width: 150px;
    }

    .header {
        display: none;
    }
    .holder {
        border-top: 1px solid #dcdee6;
        padding: 16px 16px 32px;
    }
    .isCollapsed .holder {
        display: none;
    }
    .mobile {
        position: relative;
        text-align: left;
        display: block;
        padding: 16px 16px 16px 0;
    }
    .mobile strong {
        display: block;
        font-weight: 600;
        font-size: 1.2rem;
    }
    .mobile p {
        display: inline-block;
        margin: 6px 0 0;
        border-radius: 16px;
        line-height: 16px;
        padding: 5px 16px;
        font-size: 1.2rem;
        font-weight: 600;
        text-transform: uppercase;
    }
    .mobile .toggle {
        position: absolute;
        right: 0;
        transform: translateY(-50%);
        top: 50%;
        font-size: 1.4rem;
        line-height: 22px;
        font-weight: 600;
        color: #ff4056;
        cursor: pointer;
    }
    .mobile .toggle span {
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
    }
    .isCollapsed .mobile svg {
        transform: rotate(180deg);
    }
    .mobile svg {
        transition: transform 0.3s ease-in;
        transform: none;
        display: inline-block;
        vertical-align: middle;
        height: 8px;
    }
    .mobile svg path {
        stroke: currentColor;
    }

    .isNew .holder p {
        margin: 20px 0 45px;
    }
}

@media screen and (max-width: 767px) {
    .summary h3 {
        font-size: 1.8rem !important;
        line-height: 22px !important;
    }
    .summary p {
        font-size: 1.2rem !important;
        line-height: 18px !important;
    }
}

@media screen and (max-width: 419px) {
    .content {
        padding-left: 15px;
        padding-right: 15px;
        max-width: 342px;
    }
}
