.dialog {
    width: 472px;
    max-width: 472px;
}

@media (max-width: 472px) {
    .dialog :global(.container-fluid) {
        padding: 0;
    }
}

@media (max-width: 767px) {
    .dialog {
        margin-left: 15px;
        margin-right: 15px;
        width: calc(100% - 30px);
    }
}
