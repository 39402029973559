.root {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    outline: 0;
    height: 100vh;
    width: 100%;
    text-align: center;
    background-color: rgba(28, 30, 37, 0.3);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.root > div {
    animation-name: modalStart;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    opacity: 0;
}

.root.exiting > div {
    animation-direction: reverse;
    opacity: 1;
}

:global(.modal-open #root) {
    overflow: hidden;
    position: fixed;
}

@media screen and (max-width: 767px) {
    .root {
        display: block;
    }
}

@keyframes modalStart {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
