.header {
    padding-bottom: 41px;
    border: 1px solid #dcdee6;
}
.searchHolder {
    float: right;
    width: 302px;
    position: relative;
    margin-right: 24px;
}

.actionHolder {
    float: right;
}

.actionHolder svg g path {
    fill: #fff;
}

@media screen and (max-width: 991px) {
    .actionHolder {
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        bottom: 24px;
        z-index: 200;
    }

    .searchHolder {
        clear: both;
        float: none;
        width: 100%;
        margin: none;
    }
    .searchHolder > svg {
        display: block;
    }
    .searchHolder > div {
        display: none;
    }
    .searchHolder > div.isSearchShown {
        display: block;
    }
}
