.root {
    min-height: 132px;
}

.root > div > div {
    margin-bottom: 36px !important;
}
.root > div > div > div {
    margin-right: 32px;
}
.root > div > div:nth-of-type(4n) > div {
    margin-right: 0;
}
.root > div > div:last-of-type > div {
    margin-right: 0;
}

.root > div > div > div > img {
    max-width: 168px;
    max-height: 168px;
    text-align: center;
    margin-top: 50%;
    transform: translateY(-50%)
}

@media screen and (max-width: 991px) {
    .root > div > div > div,
    .root > div > div:nth-of-type(4n) > div {
        margin-right: 52px;
    }
    .root > div > div:nth-of-type(3n) > div {
        margin-right: 0;
    }
}

@media screen and (max-width: 767px) {
    .root > div > div {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-bottom: 24px !important;
    }
    .root > div > div > div,
    .root > div > div:nth-of-type(3n) > div,
    .root > div > div:nth-of-type(4n) > div {
        width: 132px;
        height: 132px;
        margin-right: 16px;
        margin-bottom: 0px;
    }
    .root > div > div:last-of-type > div {
        background-size: 132px 132px;
        padding: 0;
        margin: 0;
     }
    .root > div > div:last-of-type > div > div {
        height: 132px;
    }
    .root > div > div:last-of-type > div > div > div > div {
        padding-top: 30px;
    }
    .root > div > div:last-of-type > div > div > div > div > div {
        height: 60px;
    }
    .root > div > div > div > img {
        max-width: 129px;
        max-height: 129px;
    }
}

@media screen and (max-width: 556px) {
    .root > div > div > div {
        margin-right: 17px;
    }
    .root > div > div:last-of-type > div {
        margin-bottom: 0px;
    }
}

@media screen and (max-width: 450px) {
    .root > div > div:nth-of-type(even) > div {
        margin-right: 0;
    }
}
@media screen and (max-width: 380px) {
    .root > div > div  {
        margin-bottom: 17px !important;
        margin-right: 40px;

    }
}
