.root {
    padding: 20px 0 0;
}

.content h1 {
    font-size: 3.4rem;
    line-height: 34px;
    font-weight: 400;
    margin: 0;
}

.content h2 {
    font-weight: 400;
    font-size: 2.8rem;
    line-height: 32px;
    margin: 0 0 25px;
}

.content h3 {
    font-weight: 400;
    font-size: 2.2rem;
    line-height: 30px;
    margin: 16px 0;
}

.goBack {
    text-decoration: none;
    clear: both;
    transition: opacity 0.3s ease-in;
    will-change: opacity;
}

.goBack svg {
    display: inline-block;
    vertical-align: middle;
    width: 12px;
    height: 14px;
    margin-right: 12px;
}

.goBack svg path {
    fill: #c0c2cc;
}

.goBack span {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.8rem;
    line-height: 22px;
    color: #323647;
}

.goBack:hover {
    opacity: 0.8;
}

.holder {
    margin: 20px 0;
}

.holder::after {
    content: '';
    display: table;
    clear: both;
}

.content {
    float: left;
    width: calc(100% - 303px);
}

.sidebar {
    float: right;
    width: 271px;
}

.header {
    padding: 0 0 24px;
    border-bottom: 2px solid #dcdee6;
}

.header::after {
    content: '';
    display: table;
    clear: both;
}

.header .gallery {
    position: relative;
    float: left;
    width: 271px;
    height: 271px;
    background-color: #fff;
    border-radius: 6px;
    margin-right: 33px;
    cursor: pointer;
}
.header .gallery .image {
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    position: absolute;
    font-size: 0;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.header .gallery > span svg {
    position: absolute;
    right: 10px;
    bottom: 10px;
}
.header .gallery .imageCount {
    position: absolute;
    width: 47px;
    height: 27px;
    left: 10px;
    bottom: 10px;
    font-size: 12px;
    border-radius: 19px;
    background: #797d94;
    color: #fff;
    overflow: visible;
}
.header .gallery .imageCount svg {
    position: relative;
    display: inline-block;
    top: -2px;
    left: 2px;
    transform: scale(0.6);
}


.header .gallery .imageCount span {
    position: relative;
    display: inline-block;
    top: -12px;
}

.header .gallery .loupeContainer {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 47px;
    height: 27px;
    background-color: #797d94;
    border-radius: 19px;
    text-align: center;
}

.header .gallery .loupeContainer svg {
    width: 23px;
    height: 22px;
    margin-top: 2px;
    text-align: center;
}

.header .gallery .loupeContainer :global(.svgFill) {
    fill: #fff;
}

.header .placeholder {
    position: relative;
}
.header .placeholder svg {
    display: block;
    margin: 0 auto;
    margin-top: 40px;
}
.header .placeholder p {
    position: relative;
    top: -15px;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    color: #5f637a;
}

.header .description {
    float: left;
    width: calc(100% - 304px);
}

.header .categories {
    margin: 12px 0 31px;
    font-size: 1.8rem;
    line-height: 22px;
}

.header .subcategories {
    color: rgba(121, 125, 148, 0.62);
}

.header li {
    padding: 10px 6px;
    font-size: 0;
}

.header li:first-of-type {
    border-bottom: 1px solid #dcdee6;
}

.header li strong {
    margin-left: 10px;
    font-size: 1.4rem;
    width: 175px;
    display: inline-block;
    vertical-align: middle;
}

.root article .description h3 {
    margin-top: 38px;
}

.root article .description p,
.root article .ingredients p {
    font-size: 1.6rem;
    line-height: 24px;
    color: #484b61;
}

.root article .ingredients h3 {
    margin-top: 23px;
}

.header li p {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 207px);
    margin: 0;
    font-size: 1.8rem;
    line-height: 24px;
    color: #484b61;
}

.header li svg {
    width: 14px;
    height: 14px;
    display: inline-block;
    vertical-align: middle;
}

.header li svg path {
    fill: #797d94;
}

.tabs {
    white-space: nowrap;
    font-size: 0;
}

.tabs li {
    cursor: pointer;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 18px;
    padding: 23px 10px 24px;
    display: inline-block;
    border-bottom: 2px solid transparent;
    color: #5f637a;
}

.tabs li:hover {
    border-bottom-color: #dcdee6;
}

.root article {
    background-color: #fff;
    border-radius: 6px;
    padding: 36px 36px 35px;
    margin-bottom: 24px;
}

.root article p {
    margin: 16px 0;
}

.content hr {
    margin: 4px 0;
    display: block;
    height: 2px;
    background-color: #dcdee6;
    border: 0;
    outline: 0;
}

.description a {
    color: #797D94;
    transition: color 0.3s;
}

.description a:hover {
    color: #ff4056;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .content {
        width: calc(100% - 265px);
    }
    .sidebar {
        width: 250px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
    .content h1 {
        font-size: 2.8rem;
        line-height: 32px;
    }
    .content h2 {
        font-size: 2.2rem;
        line-height: 24px;
    }
    .content h3 {
        font-size: 1.8rem;
        line-height: 20px;
    }
}

@media screen and (max-width: 1199px) {
    .header .categories {
        margin: 10px 0 15px;
        font-size: 1.4rem;
        line-height: 18px;
    }
    .header .gallery {
        width: 220px;
        height: 220px;
        margin-right: 20px;
    }
    .header .description {
        width: calc(100% - 240px);
    }
    .header li strong {
        width: 120px;
    }
    .header li p {
        width: calc(100% - 152px);
        font-size: 1.4rem;
        line-height: 18px;
    }
    .tabs li {
        font-size: 1.2rem;
        padding: 20px 5px;
    }
    .root article .description h3,
    .root article .ingredients h3 {
        margin-top: 16px;
    }
}

@media screen and (max-width: 991px) {
    .content {
        width: 100%;
        float: none;
        padding-bottom: 75px;
    }
    .sidebar {
        width: 100%;
        float: none;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        overflow-y: auto;
        max-height: 100vh;
    }
}

@media screen and (max-width: 767px) {
    body:global(.pageProduct) :global(.content) {
        margin-top: 0;
    }

    .root {
        padding-top: 54px;
    }
    .tabs {
        overflow-y: auto;
    }
    .goBack {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        z-index: 5;
        background-color: #fff;
        display: block;
        padding: 15px 16px;
        border-bottom: 1px solid #dcdee6;
    }
}

@media screen and (min-width: 640px) and (max-width: 767px) {
    .content h1 {
        font-size: 2.5rem;
        line-height: 28px;
    }
    .content h2 {
        font-size: 2rem;
        line-height: 22px;
    }
    .content h3 {
        font-size: 1.7rem;
        line-height: 21px;
    }
}

@media screen and (max-width: 639px) {
    .header .gallery {
        float: none;
        width: 330px;
        height: 330px;
        margin: 0 auto;
    }
    .header .placeholder svg {
        position: relative;
        bottom: -100px;
        transform: scale(1.5);
        margin-top: 0;
    }
    .header .placeholder p {
        top: 100px;
        font-size: 16px;
    }
    .header .description {
        width: 100%;
        float: none;
    }
    .header li strong {
        font-size: 1.2rem;
        line-height: 14px;
        width: 105px;
    }
    .header li p {
        font-size: 1.2rem;
        line-height: 14px;
        width: calc(100% - 167px);
    }
    .root article {
        padding: 24px;
    }
    .content h1 {
        font-size: 2.2rem;
        line-height: 26px;
        margin: 24px 0 8px;
    }
    .content h2 {
        font-size: 2rem;
        line-height: 24px;
        margin: 0 0 12px;
    }
    .content h3 {
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 18px;
    }
}

@media screen and (max-width: 359px) {
    .header .gallery {
        width: 290px;
        height: 290px;
    }
}
