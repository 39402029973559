.root h1 {
    font-weight: 400;
    font-size: 3.4rem;
    line-height: 48px;
    margin: 0;
    float: left;
}

.root h1 span {
    color: #b0b3bf;
    font-size: 2.2rem;
    line-height: 1;
    font-weight: 300;
    margin-left: 6px;
}

.container {
    padding: 40px 0;
    display: block;
    border-bottom: 1px solid #dcdee6;
}

.container::after {
    content: '';
    display: table;
    clear: both;
}

@media screen and (max-width: 991px) {
    .container {
        padding-top: 30px;
        padding-bottom: 15px;
    }
    .root h1 {
        font-size: 3rem;
    }
}

@media screen and (max-width: 767px) {
    .container {
        padding-top: 15px;
    }
    .root h1 {
        font-size: 2.2rem;
    }
    .root h1 span {
        font-size: 1.4rem;
    }
}