.root {
    text-transform: uppercase;
    min-width: auto !important;
}

.root + .root {
    margin-left: 15px;
}

.grow0 {
    flex-grow: 0;
}

.grow1 {
    flex-grow: 1;
}

@media (max-width: 767px) {
    .root {
        padding-left: 20px !important;
        padding-right: 21px !important;
    }
}