* {
    box-sizing: border-box;
}

html,
body {
    min-width: 320px;
    margin: 0;
    padding: 0;
}

ul,
ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

html {
    font-size: 62.5%;
}

#root {
    min-width: 320px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

body {
    font-size: 1.6rem;
    font-weight: 300;
    font-family: 'Barlow', sans-serif;
    color: #323647;
    background-color: #f2f3f7;
    overflow-x: hidden;
}

a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;
}

a:active,
a:focus,
a:hover {
    outline: 0;
}

a[disabled] {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    pointer-events: none;
}

button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    outline: none;
}

strong, b {
    font-weight: 500;
}

.modal-open {
    overflow: hidden;
}

.container {
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.container-fluid {
    width: 100%;
    padding: 0 15px;
}

.container {
    max-width: 1210px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.row + .row {
    margin-top: 15px;
}

.col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}

.pageNotFound {
    margin: 0;
    padding: 15px;
    height: calc(100vh - 108px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    line-height: 24px;
    font-weight: 500;
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-1 {
    -webkit-box-flex: 0;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}

.col-2 {
    -webkit-box-flex: 0;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}

.col-3 {
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
}

.col-4 {
    -webkit-box-flex: 0;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.col-5 {
    -webkit-box-flex: 0;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}

.col-6 {
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
}

.col-7 {
    -webkit-box-flex: 0;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}

.col-8 {
    -webkit-box-flex: 0;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}

.col-9 {
    -webkit-box-flex: 0;
    flex: 0 0 75%;
    max-width: 75%;
}

.col-10 {
    -webkit-box-flex: 0;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
}

.col-11 {
    -webkit-box-flex: 0;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
}

.col-12 {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1210px;
    }
}

input,
textarea {
    -webkit-appearance: none;
    font-family: 'Barlow', sans-serif !important;
    font-size: 18px !important;
    font-weight: 400 !important;
}

.container::after,
.container-fluid::after,
.clearfix::after {
    content: '';
    display: table;
    clear: both;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset;
}

.img-responsive-16by9 {
    display: block;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
}

.img-responsive-4by3 {
    display: block;
    height: 0;
    padding-bottom: 75%;
    overflow: hidden;
}

.section-container, .modal-container {
    max-width: 852px;
    margin: 0 auto;
    padding: 32px 36px 36px;
    background: #fff;
    border-radius: 12px;
}
.modal-container {
    padding: 50px 54px 54px;
}
.section-container h3, .modal-container h3 {
    margin: 0 0 28px;
    text-align: center;
    font-size: 18px;
}

@media screen and (max-width: 991px) {
    .bodyOverflowHiddenForMobile {
        overflow: hidden;
    }
}

@media screen and (max-width: 767px) {
    .section-container, .modal-container {
        padding: 20px 24px 24px;
    }
}

@media screen and (max-width: 479px) {
    body {
        font-size: 1.4rem;
    }
}
