.root {
    transition: 0s !important;
}
.root:hover {
    transition: box-shadow 0.2s, background-color 0.2s, !important;
}

.white:hover {
    transition: border 0.2s !important;
}

.s-size {
    font-weight: 400;
}

.upper {
    text-transform: uppercase;
}

.wide {
    width: 100%;
    min-width: auto;
}
