.image {
    display: flex;
    justify-content: center;
    margin-top: -20px;
}

.image svg {
    width: 240px;
    height: 240px;
}

.row {
    margin: 0 -15px 30px -15px;
}

.actions {
    margin-top: 32px;
}

@media (max-width: 1023px) {
    .image {
        display: none;
    }
}