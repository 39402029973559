.root .return {
    display: inline-block;
    width: 100%;
    font-size: 1.8rem;
    line-height: 1.22;
    border-bottom: 2px solid #dcdee6;
    color: #323647;
    padding: 20px 0;
    z-index: 2;
}

.root .return svg {
    width: 18px;
    height: 10px;
    margin-right: 12px;
    fill: #c0c2cc;
}

.root .content {
    width: 776px;
    margin: 0 auto 140px;
}
.root .content h2 {
    font-size: 3.4rem;
    margin: 4.8rem 0;
    font-weight: 300;
    letter-spacing: normal;
    color: #323647;
}

.root .content section {
    padding: 36px;
    margin-bottom: 24px;
    background-color: #fff;
    border-radius: 6px 6px;
}

.inputWrapper:first-child {
    padding-bottom: 24px;
}

.company h3 {
    margin: 0;
    padding: 0;
    padding-bottom: 24px;
    font-size: 2.8rem;
    font-weight: 300;
    line-height: 1.14;
    color: #323647;
}

.actions {
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 -5px 20px 0 rgba(0, 0, 0, 0.05);
}

.actions .grow0 {
    margin-right: 24px;
}

@media screen and (min-width: 1024px) {
    .inputWrapper:nth-child(2), .inputWrapper:nth-child(3) {
        display: inline-block;
        width: calc(50% - 10px);
    }
    .inputWrapper:nth-child(2) {
        margin-right: 20px;
    }
}

@media screen and (max-width: 1023px) {
    .root {
        position: absolute;
        z-index: 1000;
        top: 0;
        left: 0;
        right: 0;
        background-color: #f2f3f7;
    }
    .root .return {
        position: fixed;
        display: flex;
        align-items: center;
        height: 54px;
        padding: 0 16px;
        background-color: #fff; 
        border: none;
    }
    .root .return svg {
        height: 12px;
    }
    .root .content {
        width: calc(100% - 32px);
        margin: 78px 16px 120px;
    }
    .root .content h2 {
        font-size: 2.2rem;
        margin: 2.4rem 0;
        font-weight: normal;
        line-height: 1.18;
        color: #323647;
    }
    .root .content section {
        padding: 24px;
    }
    .root .content section h3 {
        font-size: 1.8rem;
        font-weight: normal;
        line-height: 1.22;
        color: #323647;
    }
    .root .inputWrapper:last-child {
        padding-top: 24px;
    }
    .actions {
        margin-top: 12px;
        padding: 16px;
    }
}

@media screen and (max-width: 500px) {
    .actions {
        flex-direction: column;
        align-items: center;
    }
}