.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 124px;
}

.sidebar {
    height: 100%;
    background-image: none !important;
}

.sidebar::before {
    display: none;
}

.sidebar::after {
    display: none;
}

.logo {
    padding: 40px 0;
    border-bottom: 1px solid #dcdee6;
}

.logo a {
    display: inline-block;
}

.logo img {
    width: 150px;
}

.content {
    padding-bottom: 0 !important;
}

.inner {
    text-align: right;
}

.label {
    display: inline-block;
    padding-right: 10px;
}

@media screen and (max-width: 991px) {
    .header {
        height: auto;
    }
    .sidebar {
        display: block !important;
        background: transparent !important;
    }

    .content {
        padding-left: 0 !important;
    }

    .logo {
        padding: 20px 0 20px;
        border-bottom: 0;
    }

    .logo img {
        width: 126px;
    }

    .label {
        display: none;
    }
}