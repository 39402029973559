.root {
    width: calc(100% - 175px);
}
.root .header-label {
    width: 275px;
    left: -175px;
}

@media screen and (max-width: 767px) {
    .root .header-label {
        top: -87px;
        left: 0;
    }
}