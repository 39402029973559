.root {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

@media screen and (max-width: 767px) {
    .root,
    .root thead,
    .root tbody,
    .root tfoot {
        display: block;
        width: 100%;
    }
}