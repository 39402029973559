.body {
    padding: 35px 54px 54px;
    color: #484b61;
}
.body * {
    transition: 0s, opacity 0.2s, border 0.2s !important;
}
.body form > div > div:last-of-type button:last-of-type {
    transition: background 0.2s, background-color 0.2s, box-shadow 0.2s !important;
}
.body form > div > div:last-of-type button:hover {
    transition: background 0.2s, background-color 0.2s, box-shadow 0.2s !important;
}

@media screen and (max-width: 767px) {
    .body {
        padding: 25px 25px 25px;
    }
}