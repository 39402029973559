.root {
    padding-top: 157px;
    width: 100%;
    height: 100vh;
    background-color: #fff;
}
.root::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 8px;
    background-color: #ff4056;
}
.holder {
    width: 574px;
    margin: 0 auto;
    border: 1px solid #dadde5;
    border-radius: 12px 12px;
}
.logo {
    width: 361px;
    height: 73.8px;
    margin: -37px auto 57px;
    padding: 0 61px; 
    background-color: #fff;
}
.logo img {
    width: 100%;
}
.header {
    text-align: center;
}

.header h1 {
    color: #323647;
    font-size: 3.4rem;
    font-weight: 300;
    margin: 0;
}

.header h2 {
    text-transform: uppercase;
    font-size: 1.4rem;
    letter-spacing: 2px;
    color: #797d94;
    font-weight: 300;
}

.form {
    width: 372px;
    max-width: 372px;
    margin: 71px auto 0;
}

.inputWrapper {
    margin: 38px 0;
}

.actions {
    margin-top: 45px;
}

.forgotPassword {
    margin: 55px 0 71px;
    text-align: center;
}

.forgotPassword .link {
    cursor: pointer;
    color: #00a5ff;
}

.forgotPassword .link:hover {
    opacity: 0.8;
}
.submitButton{
    width: 100%;
}

@media screen and (max-width: 594px) {
    .root {
        padding-top: 30px;
    }
    .holder {
        width: calc(100% - 22px);
        margin: 55px auto;
        padding: 10px;
    }
    .logo {
        width: 250px;
        padding: 0 10px;
        margin-top: -40px;
    }
    .header {
        margin-top: -20px;
    }
    .header h1 {
        font-size: 2.6rem;
    }
    .form {
        margin-top: 30px;
        width: 100%;
    }
}

@media screen and (max-width: 594px) and (max-height: 650px) {
    .holder {
        margin-bottom: 10px;
    }
    .header {
        margin-top: -30px;
    }
    .forgotPassword {
        margin: 30px 0;
    }
}
