.root {
    position: relative;
    max-width: 776px;
    border-radius: 12px;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    color: #484b61;
    padding: 16px 50px 16px 82px;
    margin: 0 auto 60px;
    min-height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.title {
    margin: 0;
    font-size: 16px;
    line-height: 1.13;
    font-weight: 500;
}

.title.upper {
    text-transform: uppercase;
}

.content {
    margin: 5px 0 0 0;
    font-size: 14px;
    line-height: 1.29;
    color: #484b61;
}

.icon {
    position: absolute;
    top: 15px;
    left: 16px;
    width: 50px;
    height: 50px;
}

.icon.success {
    background: url('./assets/ico-success.svg') no-repeat;
}

.icon.failure {
    background: url('./assets/ico-error.svg') no-repeat;
}

.close {
    position: absolute;
    top: 25px;
    right: 16px;
    width: 30px;
    height: 30px;
    background: url('./assets/ico-delete.svg') no-repeat;
    cursor: pointer;
    opacity: 0.6;
}

.close:hover {
    opacity: 1;
}

.root.success .title {
    color: #18a34e;
}

.root.failure .title {
    color: #ff4056;
}
