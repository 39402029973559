.root {
    text-transform: uppercase;
}

.cancel {
}

.submit {
    margin-left: 15px;
}

@media (max-width: 767px) {
}

@media (max-width: 500px) {
    .cancel {
        margin-top: 15px;
        order: 2;
    }

    .submit {
        margin-left: 0;
        order: 1;
    }
}
