.content {
    flex-grow: 1;
    padding: 0 48px 15px 48px;
}

.content header {
    margin-bottom: 60px;
    text-align: center;
}

.content header h6 {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 14px;
    color: #797d94;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 400;
}

.content header h2 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 34px;
    color: #323647;
    font-weight: 400;
}

.content header p {
    margin-top: 40px;
    font-size: 22px;
    color: #797d94;
    line-height: 1.36;
}

@media screen and (max-width: 991px) {
    .content {
        padding-left: 15px;
        padding-right: 15px;
    }

    .content header {
        margin-bottom: 23px;
    }

    .content header h6 {
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: 14px;
    }

    .content header h2 {
        font-size: 22px;
        margin-bottom: 40px;
    }

    .content header p {
        font-size: 16px;
    }
}