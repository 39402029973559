.list {
    margin: 32px 0;
}

.company {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 33px 36px;
    height: 126px;
    background-color: #fff;
    font-size: 1.4rem;
    transition: .3s box-shadow ease-in-out;
}

.company button {
    transition: 0s !important;
}
.company button:hover {
    transition:  background-color 0.2s , border 0.2s !important;
}

.company:hover {
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
}

.company+.company {
    margin-top: 16px;
}

.company .name {
    font-size: 2.2rem;
    width: 40%;
    overflow-wrap: break-word;
    overflow: hidden;
    padding-bottom: 0.15em;
    max-height: 2.15em;
}

.company .requestsCount {
    display: flex;
    align-items: center;
    width: 150px;
    height: 54px;
    font-weight: 600;
    border-left: 1px solid #dcdee6;
}

.company .requestsCount svg {
    width: 23px;
    height: 23px;
    margin: 0 9px 0 24px;
}

.company .requests {
    display: flex;
    justify-content: space-between;
    width: 260px;
    text-align: center;
}

.company .requests>div {
    width: 80px;
}

.company .requests .circle {
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin: 0 auto;
    border-radius: 50%;
    font-weight: 600;
}

.company .requests>div:first-child .circle {
    background-color: #d6f1f4;
    color: #0d7f95;
}

.company .requests>div:nth-child(2) .circle {
    background-color: #d9f5e6;
    color: #18a34e;
}

.company .requests>div:last-child .circle {
    background-color: #ffd7dc;
    color: #e6374b;
}

.company .requests .status {
    font-size: 1.1rem;
    font-weight: normal;
    margin-top: 6px;
}

.requests {
    display: flex;
    justify-content: space-between;
}

.searchHolder, .dropdownHolder {
    float: right;
    width: 300px;
    position: relative;
}

.dropdownHolder {
    width: 214px;
    margin-right: 16px;
}

.loader {
    padding: 20px 0 45px;
    font-size: 1.4rem;
    line-height: 30px;
    font-weight: 500;
    text-align: center;
    color: #ff4056;
    text-transform: uppercase;
}

.loader svg {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    margin-right: 8px;
    animation: rotate 0.8s infinite linear;
}

.loader svg path {
    fill: currentColor;
}

.emptyList {
    height: calc(100vh - 300px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.emptyList img {
    width: 200px;
    height: auto;
    display: block;
    margin: 0 auto;
}

.emptyList h2 {
    margin: 0;
    font-size: 2.2rem;
    line-height: 30px;
    font-weight: 400;
    color: #b0b3bf;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-height: 500px) {
    .emptyList img {
        width: 120px;
    }
}

@media screen and (max-width: 767px) {
    .company {
        flex-direction: column;
        height: auto;
        padding: 16px;
    }

    .company .name, .company .requestsCount {
        width: auto;
    }

    .company .requestsCount {
        height: 44px;
        border-left: none;
    }

    .company .requestsCount svg {
        margin-left: 0;
    }

    .company .requests {
        margin-bottom: 16px;
    }

    .searchHolder, .dropdownHolder {
        width: 100%;
    }

    .dropdownHolder {
        margin: 10px 0 0;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .company {
        padding-left: 24px;
        padding-right: 24px;
    }
    .company .name {
        width: 35%;
    }
}

@media screen and (max-width: 767px) {
    .company .name {
        text-align: center;
    }
}
