.root {
    display: block;
    margin: 0 auto;
    max-width: 852px;
    color: #b0b3bf;
}

.root h4 {
    display: inline-block;
    margin: -27px 0 48px;
    font-weight: 400;
}

.root h4:first-letter {
    text-transform: uppercase;
}

@media screen and (max-width: 767px) {
    .root h4 {
        margin: 0 0 24px;
        padding-left: 8px;
    }
}